<template>
    <div class="trip l-padded-y l-stack l-gap-2">
        <div
            class="trip__body l-inline l-gap-1"
            :class="{
                'trip__body--clickable':
                    trip.type !== 'TRACK' && trip.type !== 'JOURNEY',
            }"
            @click="toggleCorrectionForm"
        >
            <div>
                <ClockIcon width="24" height="24" />
            </div>
            <div class="l-stack l-gap-1 l-grow-1">
                <div v-if="hasError">
                    <Pill variant="danger">
                        {{ errorTextTranslation }}
                    </Pill>
                </div>
                <div class="trip__info l-stack l-gap-1">
                    <div>
                        <p class="trip__time">
                            {{
                                $t('shared.timeRange', {
                                    from: startHours,
                                    to: endHours,
                                })
                            }}
                        </p>
                        <p class="trip__duration">
                            {{ duration }} ({{ distance }})
                        </p>
                    </div>

                    <div>
                        <p v-if="trailer">
                            {{ trailer.name }}
                        </p>
                        <p v-if="trailerType">
                            {{ $t('trailerType') }}:
                            {{ $t(`shared.lsva.trailerTypes.${trailerType}`) }}
                        </p>
                        <p v-if="trailerWeight">
                            {{ $t('weight') }}: {{ trailerWeight }}&nbsp;{{
                                $t('shared.units.tons')
                            }}
                        </p>
                        <p v-if="trip.reason">
                            {{ $t('shared.reason') }}: {{ $t(trip.reason) }}
                        </p>
                    </div>

                    <div>
                        <Pill variant="disabled">
                            {{ $t(`shared.lsva.tripTypes.${trip.type}`) }}
                        </Pill>
                    </div>
                </div>
                <BaseAlert v-if="isRefused" variant="danger">
                    {{ trip.toll_declaration.latest_response.reason_text }}
                </BaseAlert>
            </div>
            <div>
                <IconButton
                    v-if="canRevoke"
                    :disabled="isRevokePending"
                    class="trip__delete"
                    @click="deleteTrip"
                >
                    <TrashBinIcon width="16" height="16" />
                </IconButton>
            </div>
        </div>
        <div v-if="currentTripCorrections.length">
            <LsvaTripCorrection
                v-for="correction in currentTripCorrections"
                :key="correction.id"
                :correction="correction"
            />
        </div>
        <LsvaTripCorrectionCreate
            v-if="isCorrectionFormVisible"
            ref="correctionForm"
            :trip="trip"
            :trip-corrections="currentTripCorrections"
            @close="toggleCorrectionForm"
        />
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'

import { httpHelper } from '@/utils'
import ClockIcon from '@/components/icons/ClockIcon.vue'
import IconButton from '@/components/IconButton.vue'
import LsvaTripCorrection from '@/components/lsva/LsvaTripCorrection.vue'
import Pill from '@/components/redesigned/Pill.vue'
import TrashBinIcon from '@/components/icons/TrashBinIcon.vue'
import LsvaTripCorrectionCreate from '@/components/lsva/LsvaTripCorrectionCreate.vue'
import BaseAlert from '@/components/base/BaseAlert.vue'

export default {
    name: 'LsvaTrip',
    components: {
        BaseAlert,
        ClockIcon,
        LsvaTripCorrectionCreate,
        IconButton,
        LsvaTripCorrection,
        Pill,
        TrashBinIcon,
    },
    props: {
        trip: {
            type: Object,
            required: true,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isCorrectionFormVisible: false,
            tripCorrection: {
                time: [
                    moment(this.trip.start).format('HH:mm:ss'),
                    moment(this.trip.end).format('HH:mm:ss'),
                ],
                trailer: null,
                trailerType: null,
                weight: '',
                type: null,
                reason: null,
            },
        }
    },
    computed: {
        ...mapState('lsva', ['isRevokePending', 'selectedTruck', 'trailers']),
        ...mapGetters('lsva', ['tripCorrections']),
        errorTextTranslation() {
            return this.$t(`shared.lsva.truckStatus.${this.errorText}`)
        },
        canRevoke() {
            const latestResponseType =
                this.trip.toll_declaration?.latest_response?.type ?? null
            return (
                latestResponseType !== null && latestResponseType !== 'REFUSED'
            )
        },
        isRefused() {
            return (
                this.trip.toll_declaration?.latest_response?.type ===
                    'REFUSED' &&
                this.trip.toll_declaration?.latest_response?.reason_text
            )
        },
        trailer() {
            return this.trip.type === 'TRACK' || this.trip.type === 'JOURNEY'
                ? this.trailers.find(
                      trailer => trailer.id === this.trip.trailer
                  )
                : this.trip.trailer
        },
        trailerType() {
            if (this.trip.trailer_type) {
                return this.trip.trailer_type
            }

            return this.trailer ? this.trailer.type : ''
        },
        trailerWeight() {
            if (this.trip.trailer_weight) {
                return (this.trip.trailer_weight / 1000).toFixed(2)
            }
            return this.trailer ? (this.trailer.weight / 1000).toFixed(2) : ''
        },
        duration() {
            const start =
                this.trip.type === 'TRACK' || this.trip.type === 'JOURNEY'
                    ? moment(this.trip.correction_begin)
                    : moment(this.trip.start)
            const end =
                this.trip.type === 'TRACK' || this.trip.type === 'JOURNEY'
                    ? moment(this.trip.correction_end)
                    : moment(this.trip.end)
            const duration = moment.duration(end.diff(start))

            return `${
                duration.hours()
                    ? this.$tc('shared.units.hour', duration.hours())
                    : ''
            } ${this.$tc('shared.units.minute', duration.minutes())}`
        },
        distance() {
            return this.trip.distance
                ? `${(this.trip.distance / 1000).toFixed(2)}km`
                : '0km'
        },
        startHours() {
            const start =
                this.trip.type === 'TRACK' || this.trip.type === 'JOURNEY'
                    ? this.trip.correction_begin
                    : this.trip.start

            return moment(start.split('+')[0]).format('HH:mm:ss')
        },
        endHours() {
            const end =
                this.trip.type === 'TRACK' || this.trip.type === 'JOURNEY'
                    ? this.trip.correction_end
                    : this.trip.end
            return moment(end.split('+')[0]).format('HH:mm:ss')
        },
        currentTripCorrections() {
            return this.tripCorrections.filter(
                correction =>
                    moment(correction.correction_begin).isSameOrAfter(
                        moment(this.trip.start),
                        'second'
                    ) &&
                    moment(correction.correction_end).isSameOrBefore(
                        this.trip.end,
                        'second'
                    ) &&
                    correction.truck ===
                        this.selectedTruck.toll_declaration.truck.id
            )
        },
    },
    methods: {
        ...mapActions('lsva', ['revokeDeclaration']),
        toggleCorrectionForm() {
            if (this.trip.type === 'TRACK' || this.trip.type === 'JOURNEY') {
                return
            }

            this.isCorrectionFormVisible = !this.isCorrectionFormVisible

            if (this.isCorrectionFormVisible) {
                this.$nextTick(() => {
                    this.$refs.correctionForm.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
                })
            }
        },
        deleteTrip() {
            const notification = {
                title: this.$t('shared.success'),
                text: this.$t('tripRevoked'),
            }
            const errorTitle = this.$t('shared.error')

            this.$modal.show('dialog', {
                title: this.$t('warning'),
                text: this.$t('confirmTripRevocation'),
                buttons: [
                    {
                        title: this.$t('shared.yes'),
                        handler: async () => {
                            this.$modal.hide('dialog')
                            try {
                                await this.revokeDeclaration(this.trip)
                                this.$notify(notification)
                            } catch (error) {
                                this.$notify({
                                    title: errorTitle,
                                    text: httpHelper.parseError(error),
                                })
                            }
                        },
                    },
                    {
                        title: this.$t('shared.no'),
                    },
                ],
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "trackTitle": "Manually tracked trip",
        "confirmTripRevocation": "Do you really want to revoke this trip?",
        "trailer": "Trailer",
        "trailerType": "Trailer type",
        "trip": "Trip",
        "tripRevoked": "Trip Revoked",
        "warning": "Warning!",
        "weight": "Weight",
        "SERVICE_ERROR": "Service error",
        "NO_JOURNEY": "No journey",
        "JOURNEY_WITHOUT_DEVICE": "Journey without device",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Moved without journey, vehicle loaded",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Moved without journey, non-taxable journey"
    },
    "de": {
        "trackTitle": "Manuell erfasste Fahrt",
        "confirmTripRevocation": "Möchten Sie diese Fahrt wirklich widerrufen?",
        "trailer": "Anhänger",
        "trailerType": "Anhängertyp",
        "trip": "Fahrt",
        "tripRevoked": "Fahrt widerrufen",
        "warning": "Warnung!",
        "weight": "Gewicht",
        "SERVICE_ERROR": "Servicefehler",
        "NO_JOURNEY": "Keine Fahrt",
        "JOURNEY_WITHOUT_DEVICE": "Fahrt ohne Gerät",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "LKW verladen",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Fahrt ist nicht LSVA-pflichtig"
    },
    "fr": {
        "trackTitle": "Trajet suivi manuellement",
        "confirmTripRevocation": "Voulez-vous vraiment révoquer ce trajet?",
        "trailer": "Remorque",
        "trailerType": "Type de remorque",
        "trip": "Trajet",
        "tripRevoked": "Trajet révoqué",
        "warning": "Attention!",
        "weight": "Poids",
        "SERVICE_ERROR": "Erreur de service",
        "NO_JOURNEY": "Pas de trajet",
        "JOURNEY_WITHOUT_DEVICE": "Trajet sans appareil",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Déplacé sans trajet, véhicule chargé",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Déplacé sans trajet, trajet non imposable"
    },
    "it": {
        "trackTitle": "Viaggio tracciato manualmente",
        "confirmTripRevocation": "Vuoi davvero revocare questo viaggio?",
        "trailer": "Rimorchio",
        "trailerType": "Tipo di rimorchio",
        "trip": "Viaggio",
        "tripRevoked": "Viaggio revocato",
        "warning": "Attenzione!",
        "weight": "Peso",
        "SERVICE_ERROR": "Errore del servizio",
        "NO_JOURNEY": "Nessun viaggio",
        "JOURNEY_WITHOUT_DEVICE": "Viaggio senza dispositivo",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Spostato senza viaggio, veicolo caricato",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Spostato senza viaggio, viaggio non tassabile"
    }
}
</i18n>

<style lang="scss" scoped>
.trip {
    padding: 16px;
    border-bottom: $style-border;

    &__delete {
        display: none;
    }

    &__body {
        &--clickable {
            cursor: pointer;
        }

        &:hover {
            .trip__delete {
                display: block;
                color: $color-danger;
            }
        }
    }

    &__info {
        line-height: 1.5;
        font-size: 15px;
    }

    &__time {
        font-weight: bold;
    }

    &__duration {
        color: $color-gray;
    }
}
</style>
