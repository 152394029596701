<template>
    <div class="lsva-truck-edit">
        <div class="lsva-truck-edit__header">
            <BaseAvatar :src="truck.marker" :color="truck.color" :size="64" />
            <span>{{ truck.name }}</span>
        </div>
        <div class="lsva-truck-edit__form">
            <BaseInput
                v-model="truckForm.vin"
                :placeholder="$t('VIN')"
                :maxlength="17"
                block
            />
            <DateInput
                v-model="truckForm.end_date"
                :placeholder="$t('registrationEnd')"
                block
            />
        </div>
        <div class="lsva-truck-edit__footer">
            <BaseButton
                :disabled="!isFormValid"
                :is-loading="isTruckPending"
                size="small"
                @click="edit"
            >
                {{ $t('save') }}
            </BaseButton>
            <BaseButton size="small" variant="outline" @click="cancel">
                {{ $t('cancel') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BaseInput from '@/components/redesigned/BaseInput.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import DateInput from '@/components/redesigned/DateInput.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'
import { httpHelper } from '@/utils'

const DEFAULT_TRUCK = {
    vin: '',
}

export default {
    name: 'LsvaTruckEdit',
    components: {
        BaseButton,
        BaseInput,
        BaseAvatar,
        DateInput,
    },
    props: {
        truck: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            truckForm: { ...this.truck },
        }
    },
    computed: {
        ...mapState('lsva', ['isTruckPending']),
        isFormValid() {
            return this.truckForm.vin.length === 17
        },
    },
    methods: {
        ...mapActions('lsva', ['updateTruck']),
        async edit() {
            const successNotification = {
                title: this.$t('shared.success'),
                text: this.$t('truckUpdated'),
            }
            const errorTitle = this.$t('shared.error')

            try {
                await this.updateTruck(this.truckForm)

                this.$notify(successNotification)
            } catch (e) {
                this.$notify({
                    title: errorTitle,
                    text: httpHelper.parseError(e),
                })
            }

            this.cleanForm()
            this.$emit('close')
        },
        cancel() {
            this.cleanForm()
            this.$emit('close')
        },
        cleanForm() {
            this.truckForm = { ...DEFAULT_TRUCK }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "save": "Save",
        "cancel": "Cancel",
        "VIN": "VIN",
        "truckUpdated": "Truck updated",
        "registrationEnd": "End of Registration"
    },
    "de": {
        "save": "Speichern",
        "cancel": "Abbrechen",
        "VIN": "VIN",
        "truckUpdated": "Lastwagen aktualisiert",
        "registrationEnd": "Registrierungsende"
    },
    "fr": {
        "save": "Enregistrer",
        "cancel": "Annuler",
        "VIN": "VIN",
        "truckUpdated": "Camion mis à jour",
        "registrationEnd": "Fin d'enregistrement"
    },
    "it": {
        "save": "Salva",
        "cancel": "Annulla",
        "VIN": "VIN",
        "truckUpdated": "Camion aggiornato",
        "registrationEnd": "Fine registrazione"
    }
}
</i18n>

<style lang="scss">
.lsva-truck-edit {
    padding: 24px;
    border-radius: 12px;
    background: $color-gray-light-2;

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        gap: 16px;
        font-weight: 700;
    }

    &__form {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
    }

    &__footer {
        margin-top: 24px;
        display: flex;
        gap: 8px;
    }
}
</style>
