<template>
    <div class="base-alert" :class="`base-alert--${variant}`">
        <div>
            <InfoIcon color="currentColor" class="alert__icon" />
        </div>
        <div>
            <slot />
        </div>
    </div>
</template>

<script>
import InfoIcon from '@/components/icons/InfoIcon.vue'

export default {
    name: 'BaseAlert',
    components: { InfoIcon },
    props: {
        variant: {
            type: String,
            default: 'default',
            validator: value => {
                return [
                    'default',
                    'primary',
                    'success',
                    'warning',
                    'danger',
                ].includes(value)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.base-alert {
    padding: 16px;
    display: flex;
    gap: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    &__icon {
        width: 24px;
        height: 24px;
        color: $color-danger;
    }

    &--default {
        background-color: $color-gray-light-new;
    }

    &--primary {
        color: darken($color-blue-light, 40%);
        border: 1px solid $color-blue-light;
        background-color: lighten($color-blue-light, 40%);
    }

    &--success {
        color: darken($color-success, 40%);
        border: 1px solid $color-success;
        background-color: lighten($color-success, 40%);
    }

    &--warning {
        color: darken($color-orange, 40%);
        border: 1px solid $color-orange;
        background-color: lighten($color-orange, 40%);
    }

    &--danger {
        color: $color-danger;
        border: 1px solid $color-danger;
        background-color: $color-danger-light;
    }
}
</style>
