<template>
    <div class="lsva-truck-details">
        <div class="lsva-truck-details__head">
            <h3>{{ $t('heading') }}</h3>
            <IconButton @click="$emit('close')">
                <RemoveIcon width="24" height="24" />
            </IconButton>
        </div>
        <div v-if="isEditing && selectedTruck" class="l-padded">
            <LsvaTruckEdit
                :truck="selectedTruck.toll_declaration.truck"
                @close="toggleEditTruckForm"
            />
        </div>
        <div v-else class="lsva-truck-details__info">
            <div class="lsva-truck-details__icon">
                <BaseAvatar
                    :src="selectedTruck.toll_declaration.truck.marker"
                    :color="selectedTruck.toll_declaration.truck.color"
                    :size="64"
                />
            </div>
            <div class="lsva-truck-details__data l-grow-1">
                <h4>{{ selectedTruck.toll_declaration.truck.name }}</h4>

                <p>VIN {{ selectedTruck.toll_declaration.truck.vin }}</p>
                <p>
                    {{ $t('shared.lsva.columns.endOfRegistration') }}
                    {{
                        selectedTruck.toll_declaration.truck.end_date ||
                            $t('noEnd')
                    }}
                </p>
            </div>
            <div class="lsva-truck-details__edit">
                <IconButton @click="toggleEditTruckForm">
                    <PencilIcon width="16" height="16" />
                </IconButton>
            </div>
        </div>
        <div class="l-padded-x">
            <BaseAlert
                v-if="selectedTruck.needs_manual_correction_cause"
                :variant="
                    selectedTruck.needs_manual_correction ? 'danger' : 'default'
                "
            >
                <div class="l-stack l-gap-2">
                    <div>
                        {{
                            $t(
                                `correctionCauses.${selectedTruck.needs_manual_correction_cause}`
                            )
                        }}
                    </div>
                    <div class="l-inline l-gap-1">
                        <BaseButton
                            v-if="selectedTruck.needs_manual_correction"
                            size="small"
                            variant="danger"
                            :disabled="isErrorAcknowledgementPending"
                            @click="acknowledgeError"
                        >
                            {{ $t('acknowledgeError') }}
                        </BaseButton>
                        <BaseButton
                            v-if="!isTripsLoading && !hasNoJourney"
                            size="small"
                            variant="danger"
                            @click="addJourney"
                        >
                            {{ $t('noJourneys') }}
                        </BaseButton>
                    </div>
                </div>
            </BaseAlert>
        </div>
        <div class="lsva-truck-details__info">
            <div
                class="lsva-truck-details__data lsva-truck-details__data--accent"
            >
                {{ formattedDate }}
            </div>
            <BaseButton
                size="small"
                variant="outline"
                @click="handleCreateTripClick"
            >
                {{ $t('addTrip') }}
            </BaseButton>
        </div>
        <div class="lsva-truck-details__trips">
            <VSpinner
                v-if="isTripsLoading"
                :size="24"
                :speed="1"
                line-fg-color="black"
                line-bg-color="transparent"
                class="lsva-truck-details__trips-spinner"
            />
            <template v-else>
                <LsvaTrip
                    v-for="trip in preparedTrips"
                    :key="trip.id"
                    :trip="trip"
                    :has-error="selectedTruck.needs_manual_correction"
                    :error-text="selectedTruckErrorText"
                />
            </template>
            <div v-if="isCreateTripVisible" class="l-padded">
                <LsvaTripCreate
                    ref="createTripForm"
                    :title="$t('addTrip')"
                    @close="handleCloseCreateTrip"
                    @create="handleCloseCreateTrip"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import VSpinner from 'vue-simple-spinner'
import { mapState, mapGetters, mapActions } from 'vuex'

import BaseAlert from '@/components/base/BaseAlert.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import IconButton from '@/components/IconButton.vue'
import LsvaTrip from '@/components/lsva/LsvaTrip.vue'
import LsvaTripCreate from '@/components/lsva/LsvaTripCreate.vue'
import LsvaTruckEdit from '@/components/lsva/LsvaTruckEdit.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import RemoveIcon from '@/components/icons/RemoveIcon.vue'
import { httpHelper } from '@/utils'

export default {
    name: 'LsvaTruckDetailsView',
    components: {
        BaseAlert,
        BaseAvatar,
        BaseButton,
        IconButton,
        LsvaTrip,
        LsvaTripCreate,
        LsvaTruckEdit,
        PencilIcon,
        RemoveIcon,
        VSpinner,
    },
    data() {
        return {
            isEditing: false,
            isCreateTripVisible: false,
        }
    },
    computed: {
        ...mapState('lsva', [
            'selectedDay',
            'selectedTruck',
            'isTripsLoading',
            'isErrorAcknowledgementPending',
        ]),
        ...mapGetters('lsva', ['preparedTrips', 'hasNoJourney']),
        formattedDate() {
            return moment(this.selectedDay.date).format('dddd, D. MMMM')
        },
        selectedTruckErrorText() {
            if (
                this.selectedTruck.declaration_type === 'GNSS' &&
                this.selectedTruck.gnss_without_trip
            ) {
                return 'GNSS_WITHOUT_TRIP'
            } else if (
                this.selectedTruck.declaration_type === 'GNSS' &&
                !this.selectedTruck.gnss_without_trip
            ) {
                return 'GNSS_WITH_TRIP'
            }

            return this.selectedTruck.declaration_type
        },
    },
    watch: {
        selectedTruck() {
            this.isCreateTripVisible = false
        },
    },
    methods: {
        ...mapActions('lsva', ['createCorrection', 'sendErrorAcknowledgement']),
        toggleEditTruckForm() {
            this.isEditing = !this.isEditing
        },
        handleCreateTripClick() {
            this.isCreateTripVisible = true
            this.$nextTick(() => {
                this.$refs.createTripForm.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            })
        },
        handleCloseCreateTrip() {
            this.isCreateTripVisible = false
        },
        acknowledgeError() {
            const notification = {
                title: this.$t('shared.success'),
                text: this.$t('errorAcknowledged'),
            }
            const errorTitle = this.$t('shared.error')

            this.$modal.show('dialog', {
                title: this.$t('acknowledgeError'),
                text: this.$t('acknowledgeErrorText'),
                buttons: [
                    {
                        title: this.$t('confirm'),
                        handler: async () => {
                            this.$modal.hide('dialog')
                            try {
                                await this.sendErrorAcknowledgement()
                                this.$notify(notification)
                            } catch (error) {
                                this.$notify({
                                    title: errorTitle,
                                    text: httpHelper.parseError(error),
                                })
                            }
                        },
                    },
                    {
                        title: this.$t('cancel'),
                    },
                ],
            })
        },
        async addJourney() {
            const notification = {
                title: this.$t('shared.success'),
                text: this.$t('correctionCreated'),
            }

            try {
                this.createCorrection({
                    type: 'JOURNEY',
                    reason: 'NO_JOURNEY',
                    time: ['00:00:00', '23:59:59'],
                })

                this.$notify(notification)
            } catch (error) {
                this.$notify({
                    title: this.$t('shared.error'),
                    text: httpHelper.parseError(error),
                })
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "addTrip": "Add trip",
        "noJourneys": "No journeys",
        "acknowledgeError": "Acknowledge error",
        "acknowledgeErrorText": "Please confirm that you have acknowledged the tracking error and have recorded any LSV-required trips.",
        "correctionCreated": "Correction created",
        "confirm": "Confirm",
        "cancel": "Cancel",
        "errorAcknowledged": "Error acknowledged",
        "heading": "Edit",
        "noEnd": "No end",
        "noTrackingWarningOptional": "The signal to the tracker was not found. No trips were tracked. You can optionally record trips.",
        "trailer": "Trailer",
        "trip": "Trip",
        "weight": "Weight",
        "correctionCauses": {
            "CONNECTION_LOST": "No messages were received throughout the day. Therefore, trips must be entered manually.",
            "NOT_INSTALLED": "The recording device has not yet been put into operation. Therefore, journeys must be added manually.",
            "INSTALLATION_DATE": "The recording device was put into operation on this day. Therefore, any trips before the start of operation must be added manually.",
            "CONNECTION_LOST_ON_NEXT_DAY": "The recording device potentially lost connection during the day. Therefore, any trips must be added manually."
        }
    },
    "de": {
        "addTrip": "Fahrt hinzufügen",
        "noJourneys": "Keine Fahrten",
        "acknowledgeError": "Fehler quittieren",
        "acknowledgeErrorText": "Bitte bestätigen Sie, dass Sie den Trackingfehler zur Kenntnis genommen haben und allfällige LSV-pflichtigen Fahrten erfasst haben.",
        "correctionCreated": "Zwischenstrecke erstellt",
        "confirm": "Bestätigen",
        "cancel": "Abbrechen",
        "errorAcknowledged": "Fehler quittiert",
        "heading": "Bearbeiten",
        "noEnd": "Kein Ende",
        "noTrackingWarningOptional": "Das Signal zum Tracker wurde nicht gefunden. Es wurden keine Fahrten getrackt. Sie können optional Fahrten erfassen.",
        "trailer": "Anhänger",
        "trip": "Fahrt",
        "weight": "Gewicht",
        "correctionCauses": {
            "CONNECTION_LOST": "Während des gesamten Tages wurden keine Nachrichten empfangen. Deshalb müssen Fahrten oder das Nichtvorhandensein von Fahrten manuell nachgetragen werden.",
            "NOT_INSTALLED": "Das Erfassungsgerät wurde noch nicht in Betrieb genommen. Deshalb müssen Fahrten oder das Nichtvorhandensein von Fahrten manuell nachgetragen werden.",
            "INSTALLATION_DATE": "Das Erfassungsgerät wurde an diesem Tag in Betrieb genommen. Deshalb müssen allfällige Fahrten vor der Inbetriebnahme nachtragen werden.",
            "CONNECTION_LOST_ON_NEXT_DAY": "Das Erfassungsgerät hat während des Tages potenziell die Verbindung verloren. Deshalb müssen allfällige Fahrten manuell nachgetragen werden."
        }
    },
    "fr": {
        "noJourneys": "Aucun trajet",
        "heading": "Editer",
        "acknowledgeError": "Reconnaître l'erreur",
        "acknowledgeErrorText": "Veuillez confirmer que vous avez reconnu l'erreur de suivi et avez enregistré les trajets nécessitant la LSV.",
        "correctionCreated": "Correction créée",
        "confirm": "Confirmer",
        "cancel": "Annuler",
        "errorAcknowledged": "Erreur reconnue",
        "noTrackingWarningOptional": "Le signal au tracker n'a pas été trouvé. Aucun trajet n'a été suivi. Vous pouvez enregistrer des trajets en option.",
        "addTrip": "Ajouter un trajet",
        "trailer": "Remorque",
        "weight": "Poids",
        "trip": "Trajet",
        "noEnd": "Pas de fin",
        "correctionCauses": {
            "CONNECTION_LOST": "Aucun message n'a été reçu tout au long",
            "NOT_INSTALLED": "L'appareil d'enregistrement n'a pas encore été mis en service. Les voyages doivent donc être ajoutés manuellement.",
            "INSTALLATION_DATE": "L'appareil d'enregistrement a été mis en service ce jour-là. Par conséquent, les voyages avant le début de l'exploitation doivent être ajoutés manuellement.",
            "CONNECTION_LOST_ON_NEXT_DAY": "L'appareil d'enregistrement a potentiellement perdu la connexion pendant la journée. Par conséquent, les voyages doivent être ajoutés manuellement."
        }
    },
    "it": {
        "noJourneys": "Nessun viaggio",
        "heading": "Modificare",
        "acknowledgeError": "Riconosci l'errore",
        "acknowledgeErrorText": "Conferma di aver riconosciuto l'errore di tracciamento e di aver registrato eventuali viaggi che richiedono l'LSV.",
        "correctionCreated": "Correzione creata",
        "confirm": "Conferma",
        "cancel": "Annulla",
        "errorAcknowledged": "Errore riconosciuto",
        "noTrackingWarningOptional": "Il segnale al tracker non è stato trovato. Nessun viaggio è stato tracciato. Puoi registrare i viaggi in opzione.",
        "addTrip": "Aggiungi viaggio",
        "trailer": "Rimorchio",
        "weight": "Peso",
        "trip": "Viaggio",
        "noEnd": "Nessuna fine",
        "correctionCauses": {
            "CONNECTION_LOST": "Nessun messaggio è stato ricevuto per tutto il giorno. Pertanto, i viaggi devono essere inseriti manualmente.",
            "NOT_INSTALLED": "Il dispositivo di registrazione non è ancora stato messo in funzione. Pertanto, i viaggi devono essere inseriti manualmente.",
            "INSTALLATION_DATE": "Il dispositivo di registrazione è stato messo in funzione in questo giorno. Pertanto, i viaggi prima dell'inizio dell'operazione devono essere inseriti manualmente.",
            "CONNECTION_LOST_ON_NEXT_DAY": "Il dispositivo di registrazione ha potenzialmente perso la connessione durante il giorno. Pertanto, i viaggi devono essere inseriti manualmente."
        }
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-truck-details {
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: $style-border;
        padding: 16px;
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 16px;
    }

    &__data {
        &--accent {
            font-weight: bold;
        }
    }

    &__trips {
        border-top: $style-border;
    }

    &__trips-spinner {
        margin-top: 8px;
    }
}
</style>
