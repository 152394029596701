<template>
    <div
        class="truck-marker"
        :style="{
            border,
            borderColor,
            width: size + 'px',
            height: size + 'px',
            minWidth: size + 'px',
        }"
    >
        <img :src="src" class="truck-marker__img" alt="Avatar" />
    </div>
</template>

<script>
export default {
    name: 'BaseAvatar',
    props: {
        src: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: '#000000',
        },
        size: {
            type: Number,
            default: 64,
        },
    },
    computed: {
        border() {
            const borderSize = Math.round(this.size * 0.045)
            return `${borderSize}px solid ${this.borderColor}`
        },
        borderColor() {
            return this.color
        },
    },
}
</script>

<style lang="scss" scoped>
.truck-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-style: solid;

    &__img {
        width: 70%;
        height: auto;
    }
}
</style>
