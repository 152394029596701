<template>
    <div class="lsva-trucks-filter l-inline l-center-v l-gap-2">
        <div>
            <BaseInput
                :value="$route.query.search"
                :debounce="400"
                :placeholder="$t('searchForTrucks')"
                block
                @input="setSearchQuery"
            >
                <template #icon>
                    <SearchIcon width="16" height="16" />
                </template>
            </BaseInput>
        </div>
        <div>
            <BaseMultiselect
                :value="$route.query.status"
                :placeholder="$t('allStatuses')"
                :options="statusOptions"
                :custom-label="
                    option => $t(`shared.lsva.truckStatus.${option}`)
                "
                block
                @input="setSearchStatus"
            />
        </div>
        <div>
            <BaseButton
                v-if="$route.query.search || $route.query.status"
                size="small"
                variant="ghost"
                @click="searchReset"
            >
                <span class="l-inline l-gap-1">
                    <ResetIcon width="18" height="16" />
                    <span>{{ $t('reset') }} </span>
                </span>
            </BaseButton>
        </div>
    </div>
</template>

<script>
import BaseMultiselect from '../redesigned/BaseMultiselect.vue'
import BaseInput from '../redesigned/BaseInput.vue'
import SearchIcon from '../icons/SearchIcon.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import ResetIcon from '@/components/icons/ResetIcon.vue'

export default {
    name: 'LsvaTrucksFilter',
    components: {
        ResetIcon,
        BaseButton,
        BaseInput,
        BaseMultiselect,
        SearchIcon,
    },
    data() {
        return {
            statusOptions: [
                'ALL',
                'VEHICLE_OUTSIDE_TOLL_DOMAIN',
                'GNSS_WITH_TRIP',
                'GNSS_WITHOUT_TRIP',
                'WITHOUT_ACCESS',
            ],
        }
    },
    methods: {
        searchReset() {
            // redirect to the same page with search and status excluded from query
            const newQuery = { ...this.$route.query }
            delete newQuery.search
            delete newQuery.status

            this.$router.push({
                query: newQuery,
            })
        },
        setSearchQuery(value) {
            if (!value) {
                const newQuery = { ...this.$route.query }
                delete newQuery.search
                this.$router.push({
                    query: newQuery,
                })

                return
            }

            this.$router.push({
                query: {
                    ...this.$route.query,
                    search: value,
                },
            })
        },
        setSearchStatus(value) {
            if (value === 'ALL') {
                const newQuery = { ...this.$route.query }
                delete newQuery.status
                this.$router.push({
                    query: newQuery,
                })

                return
            }

            this.$router.push({
                query: {
                    ...this.$route.query,
                    status: value,
                },
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allStatuses": "All Messages",
        "reset": "Reset",
        "searchForTrucks": "Search for trucks"
    },
    "de": {
        "allStatuses": "Alle Meldungen",
        "reset": "Zurücksetzen",
        "searchForTrucks": "Suche nach Lastwagen"
    },
    "fr": {
        "allStatuses": "Tous les Messages",
        "reset": "Réinitialiser",
        "searchForTrucks": "Recherche de camions"
    },
    "it": {
        "allStatuses": "Tutti i Messaggi",
        "reset": "Ripristina",
        "searchForTrucks": "Ricerca di camion"
    }
}
</i18n>
