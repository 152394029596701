<template>
    <span class="pill" :class="`pill--${variant}`">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'Pill',
    props: {
        variant: {
            type: String,
            default: 'default',
            validator: value => {
                return [
                    'default',
                    'disabled',
                    'primary',
                    'success',
                    'warning',
                    'danger',
                ].includes(value)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.pill {
    display: inline-block;
    margin-top: 4px;
    margin-right: 8px;
    padding: 6px 12px;
    border-radius: 16px;
    font-weight: 500;
    font-size: 13px;

    &--default {
        background-color: $color-gray-light-new;
    }

    &--primary {
        color: $color-pill-blue;
        border: 1px solid $color-pill-blue;
        background-color: $color-pill-blue-light;
    }

    &--success {
        color: darken($color-success, 40%);
        border: 1px solid $color-success;
        background-color: lighten($color-success, 40%);
    }

    &--warning {
        color: darken($color-orange, 40%);
        border: 1px solid $color-orange;
        background-color: lighten($color-orange, 40%);
    }

    &--disabled {
        color: $color-gray;
        border: 1px solid $color-gray;
        background-color: $color-gray-light-2;
    }

    &--danger {
        color: $color-danger;
        border: 1px solid $color-danger;
        background-color: $color-danger-light;
    }
}
</style>
