export const TRAILER_TYPES = ['S', 'T']

export const CORRECTION_TYPES = ['TRAILER', 'JOURNEY']

export const JOURNEY_REASONS = [
    'NO_JOURNEY',
    'MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED',
    'MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY',
]

export const TRIP_REASONS = [
    'SERVICE_ERROR',
    'SERVICE_REGISTRATION',
    'JOURNEY_WITHOUT_DEVICE',
]
