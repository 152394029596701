<template>
    <div class="trip-correction l-inline l-gap-1">
        <div>
            <ChildIcon width="22" height="22" />
        </div>
        <div class="l-stack l-gap-1 l-grow-1">
            <div class="trip-correction__info l-stack l-gap-1">
                <div>
                    <p class="trip-correction__time">
                        {{
                            $t('shared.timeRange', {
                                from: startHours,
                                to: endHours,
                            })
                        }}
                    </p>
                    <p class="trip-correction__duration">
                        {{ duration }}
                    </p>
                </div>
                <div>
                    <p v-if="trailer">
                        {{ trailer.name }}
                    </p>
                    <p v-if="trailerType">
                        {{ $t('trailerType') }}:
                        {{ $t(`shared.lsva.trailerTypes.${trailerType}`) }}
                    </p>
                    <p v-if="trailerWeight">
                        {{ $t('weight') }}: {{ trailerWeight }}&nbsp;{{
                            $t('shared.units.tons')
                        }}
                    </p>
                    <p
                        v-if="
                            correction.type === 'JOURNEY' && correction.reason
                        "
                    >
                        {{ $t('shared.reason') }}: {{ $t(correction.reason) }}
                    </p>
                </div>
                <div>
                    <Pill variant="primary">
                        {{ $t(`shared.lsva.tripTypes.${correction.type}`) }}
                    </Pill>
                </div>
            </div>
            <BaseAlert v-if="isRefused" variant="danger">
                {{ correction.toll_declaration.latest_response.reason_text }}
            </BaseAlert>
        </div>
        <div>
            <IconButton
                v-if="canRevoke"
                :disabled="isRevokePending"
                class="trip-correction__delete"
                @click="deleteCorrection"
            >
                <TrashBinIcon width="16" height="16" />
            </IconButton>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import { httpHelper } from '@/utils'
import IconButton from '@/components/IconButton.vue'
import TrashBinIcon from '@/components/icons/TrashBinIcon.vue'
import ChildIcon from '@/components/icons/ChildIcon.vue'
import Pill from '@/components/redesigned/Pill.vue'
import BaseAlert from '@/components/base/BaseAlert.vue'

export default {
    name: 'LsvaTripCorrection',
    components: {
        BaseAlert,
        Pill,
        ChildIcon,
        IconButton,
        TrashBinIcon,
    },
    props: {
        correction: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('lsva', ['trailers', 'isRevokePending']),
        trailer() {
            return this.trailers.find(
                trailer => trailer.id === this.correction.trailer
            )
        },
        canRevoke() {
            const latestResponseType =
                this.correction.toll_declaration?.latest_response?.type ?? null
            return (
                latestResponseType !== null && latestResponseType !== 'REFUSED'
            )
        },
        isRefused() {
            return (
                this.correction.toll_declaration?.latest_response?.type ===
                    'REFUSED' &&
                this.correction.toll_declaration?.latest_response?.reason_text
            )
        },
        trailerWeight() {
            if (this.correction.trailer_weight) {
                return (this.correction.trailer_weight / 1000).toFixed(2)
            }
            return this.trailer ? (this.trailer.weight / 1000).toFixed(2) : ''
        },
        trailerType() {
            if (this.correction.trailer_type) {
                return this.correction.trailer_type
            }

            return this.trailer ? this.trailer.type : ''
        },
        duration() {
            const start = moment(this.correction.correction_begin)
            const end = moment(this.correction.correction_end)
            const duration = moment.duration(end.diff(start))

            return `${
                duration.hours()
                    ? this.$tc('shared.units.hour', duration.hours())
                    : ''
            } ${this.$tc('shared.units.minute', duration.minutes())}`
        },
        startHours() {
            return moment(
                this.correction.correction_begin.split('+')[0]
            ).format('HH:mm:ss')
        },
        endHours() {
            return moment(this.correction.correction_end.split('+')[0]).format(
                'HH:mm:ss'
            )
        },
    },
    methods: {
        ...mapActions('lsva', ['revokeDeclaration']),
        deleteCorrection() {
            const notification = {
                title: this.$t('shared.success'),
                text: this.$t('correctionRevoked'),
            }
            const errorTitle = this.$t('shared.error')

            this.$modal.show('dialog', {
                title: this.$t('warning'),
                text: this.$t('confirmCorrectionRevocation'),
                buttons: [
                    {
                        title: this.$t('shared.yes'),
                        handler: async () => {
                            this.$modal.hide('dialog')
                            try {
                                await this.revokeDeclaration(this.correction)
                                this.$notify(notification)
                            } catch (error) {
                                this.$notify({
                                    title: errorTitle,
                                    text: httpHelper.parseError(error),
                                })
                            }
                        },
                    },
                    {
                        title: this.$t('shared.no'),
                    },
                ],
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "confirmCorrectionRevocation": "Do you really want to revoke this correction?",
        "correctionRevoked": "Correction revoked",
        "NO_JOURNEY": "No journey",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Moved without journey, vehicle loaded",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Moved without journey, non-taxable journey",
        "trailer": "Trailer",
        "trailerType": "Trailer type",
        "warning": "Warning!",
        "weight": "Weight"
    },
    "de": {
        "confirmCorrectionRevocation": "Möchten Sie diese Korrektur wirklich widerrufen?",
        "correctionRevoked": "Korrektur widerrufen",
        "NO_JOURNEY": "Keine Fahrt",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "LKW verladen",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Fahrt ist nicht LSVA-pflichtig",
        "trailer": "Anhänger",
        "trailerType": "Anhängertyp",
        "warning": "Warnung!",
        "weight": "Gewicht"
    },
    "fr": {
        "confirmCorrectionRevocation": "Voulez-vous vraiment révoquer cette correction?",
        "correctionRevoked": "Correction révoquée",
        "NO_JOURNEY": "Pas de trajet",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Déplacé sans trajet, véhicule chargé",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Déplacé sans trajet, trajet non imposable",
        "trailer": "Remorque",
        "trailerType": "Type de remorque",
        "warning": "Attention!",
        "weight": "Poids"
    },
    "it": {
        "confirmCorrectionRevocation": "Vuoi davvero revocare questa correzione?",
        "correctionRevoked": "Correzione revocata",
        "NO_JOURNEY": "Nessun viaggio",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Spostato senza viaggio, veicolo caricato",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Spostato senza viaggio, viaggio non tassabile",
        "trailer": "Rimorchio",
        "trailerType": "Tipo di rimorchio",
        "warning": "Attenzione!",
        "weight": "Peso"
    }
}
</i18n>

<style lang="scss" scoped>
.trip-correction {
    border-top: $style-border;
    margin: 0 -16px;
    padding: 16px 16px;

    &--clickable {
        cursor: pointer;
    }

    &:hover {
        .trip-correction__delete {
            display: block;
            color: $color-danger;
        }
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    &__delete {
        display: none;
    }

    &__info {
        line-height: 1.5;
        font-size: 15px;
    }

    &__time {
        font-weight: bold;
    }

    &__duration {
        color: $color-gray;
    }
}
</style>
