<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        color="transparent"
        :viewbox-width="19"
        :viewbox-height="17"
        :fill-color="color"
        @click="$emit('click')"
    >
        <g clip-path="url(#clip0_374_12735)">
            <path
                d="M10.2857 16.5C9.97029 16.5 9.71429 16.244 9.71429 15.9286C9.71429 15.6131 9.97029 15.3571 10.2857 15.3571C14.067 15.3571 17.1429 12.2813 17.1429 8.5C17.1429 4.71867 14.067 1.64286 10.2857 1.64286C6.82286 1.64286 3.91695 4.26762 3.48571 7.63219L4.73905 6.37962C4.84724 6.27143 4.99048 6.212 5.14286 6.212C5.29524 6.212 5.43924 6.27143 5.54667 6.37962C5.65486 6.48705 5.71429 6.63105 5.71429 6.78343C5.71429 6.93581 5.65486 7.07981 5.54667 7.18724L3.27162 9.46229C3.17486 9.57276 3.01943 9.64286 2.85714 9.64286C2.69486 9.64286 2.53943 9.57276 2.43048 9.4501L0.167619 7.18724C0.0594286 7.07981 0 6.93581 0 6.78343C0 6.63105 0.0594286 6.48705 0.167619 6.37962C0.275048 6.27143 0.419048 6.212 0.571429 6.212C0.72381 6.212 0.86781 6.27143 0.975238 6.37962L2.32457 7.72895C2.71695 3.68705 6.16 0.5 10.2857 0.5C14.6971 0.5 18.2857 4.08857 18.2857 8.5C18.2857 12.9114 14.6971 16.5 10.2857 16.5Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_374_12735">
                <rect
                    width="18.2857"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ResetIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: 'currentColor',
        },
    },
}
</script>
