<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :viewbox-width="16"
        :viewbox-height="16"
        :color="color"
        fill-color="none"
        @click="$emit('click')"
    >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.25 3.625v3.625h2.71875m2.71875 0a5.4375 5.4375 0 1 1 -10.875 0 5.4375 5.4375 0 0 1 10.875 0Z"
        ></path>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ClockIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: 'currentColor',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    fill-rule: evenodd;
}
</style>
