<template>
    <form class="lsva-trip-create" @submit.prevent="create">
        <h4 class="lsva-trip-create__header">{{ title }}</h4>
        <div class="lsva-trip-create__form">
            <BaseTimeRange
                v-model="trip.time"
                :is-error="$v.trip.time.$error"
                :error-text="timeError"
            />

            <BaseInput
                v-model="trip.distance"
                :placeholder="$t('distance')"
                :is-error="$v.trip.distance.$error"
                :error-text="distanceError"
                type="number"
                step="0.01"
                block
            >
                <template #icon>
                    <DistanceIcon width="16" height="16" />
                </template>
                <template #postfix>
                    {{ $t('shared.units.km') }}
                </template>
            </BaseInput>

            <BaseMultiselect
                v-model="trip.trailer"
                :options="trailersExtended"
                track-by="id"
                :custom-label="option => option.name"
                :placeholder="$t('trailer')"
                :loading="isTrailersLoading"
                :is-error="$v.trip.trailer.$error"
                :error-text="trailerError"
                label="label"
                block
            />

            <template v-if="trip.trailer && trip.trailer.id !== 'NO_TRAILER'">
                <BaseMultiselect
                    v-model="trip.trailerType"
                    :options="trailerTypes"
                    :custom-label="
                        option => $t(`shared.lsva.trailerTypes.${option}`)
                    "
                    :placeholder="$t('trailerType')"
                    :loading="isTrailersLoading"
                    :disabled="
                        trip.trailer && trip.trailer.id !== 'OTHER_TRAILER'
                    "
                    :is-error="$v.trip.trailerType.$error"
                    :error-text="trailerTypeError"
                    block
                />

                <BaseInput
                    v-model="trip.weight"
                    :placeholder="$t('weight')"
                    :disabled="
                        trip.trailer && trip.trailer.id !== 'OTHER_TRAILER'
                    "
                    :is-error="$v.trip.weight.$error"
                    :error-text="weightError"
                    type="number"
                    step="0.01"
                    block
                >
                    <template #icon>
                        <ScalesIcon width="16" height="16" />
                    </template>
                    <template #postfix>
                        {{ $t('shared.units.tons') }}
                    </template>
                </BaseInput>
            </template>

            <BaseMultiselect
                v-model="trip.message"
                :options="reasonsOptions"
                :custom-label="option => $t(`reasons.${option}`)"
                :placeholder="$t('message')"
                :is-error="$v.trip.message.$error"
                :error-text="messageError"
                block
            />
        </div>
        <div class="lsva-trip-create__footer">
            <BaseButton :is-loading="isTrackPending" type="submit" size="small">
                {{ $t('create') }}
            </BaseButton>
            <BaseButton size="small" variant="outline" @click="cancel">
                {{ $t('cancel') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import moment from 'moment'
import { minValue, required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'
import { httpHelper } from '@/utils'

import { TRAILER_TYPES, TRIP_REASONS } from '@/constants'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect.vue'
import BaseInput from '@/components/redesigned/BaseInput.vue'
import BaseButton from '@/components/redesigned/BaseButton.vue'
import BaseTimeRange from '@/components/base/BaseTimeRange.vue'
import DistanceIcon from '@/components/icons/DistanceIcon.vue'
import ScalesIcon from '@/components/icons/ScalesIcon.vue'

const DEFAULT_TRIP = {
    time: [moment().format('HH:mm:ss'), moment().format('HH:mm:ss')],
    trailer: null,
    trailerType: null,
    weight: '',
    distance: '',
    message: '',
}

export default {
    name: 'LsvaTripCreate',
    components: {
        BaseTimeRange,
        BaseButton,
        BaseMultiselect,
        BaseInput,
        DistanceIcon,
        ScalesIcon,
    },
    props: {
        title: {
            type: String,
            default: 'Create Trip',
        },
    },
    data() {
        return {
            trip: { ...DEFAULT_TRIP },
        }
    },
    computed: {
        ...mapState('lsva', [
            'trailers',
            'selectedDay',
            'selectedTruck',
            'isTrackPending',
            'isTrailersLoading',
        ]),
        ...mapGetters('lsva', ['allCorrections']),
        trailerTypes() {
            return TRAILER_TYPES
        },
        trailersExtended() {
            return [
                { id: 'NO_TRAILER', name: this.$t('noTrailer') },
                { id: 'OTHER_TRAILER', name: this.$t('otherTrailer') },
                ...this.trailers,
            ]
        },
        reasonsOptions() {
            const installationDate = moment(
                this.selectedTruck.toll_declaration.truck.installation_date
            )

            const selectedDate = moment(this.selectedDay.date)

            if (selectedDate.isBefore(installationDate)) {
                return TRIP_REASONS.filter(reason => reason !== 'SERVICE_ERROR')
            }

            if (selectedDate.isAfter(installationDate)) {
                return TRIP_REASONS.filter(
                    reason => reason !== 'SERVICE_REGISTRATION'
                )
            }

            return TRIP_REASONS
        },
        timeError() {
            if (!this.$v.trip.time.$dirty) return ''
            if (!this.$v.trip.time.required)
                return this.$t('errors.timeRequired')
            if (!this.$v.trip.time.isEndTimeAfterStartTime)
                return this.$t('errors.endTimeAfterStartTime')
            if (!this.$v.trip.time.isTimeNotOverlapping)
                return this.$t('errors.isTimeNotOverlapping')
            return ''
        },
        distanceError() {
            if (!this.$v.trip.distance.$dirty) return ''
            if (!this.$v.trip.distance.required)
                return this.$t('errors.distanceRequired')
            if (!this.$v.trip.distance.minValue)
                return this.$t('errors.distanceMinValue')
            return ''
        },
        trailerError() {
            if (!this.$v.trip.trailer.$dirty) return ''
            if (!this.$v.trip.trailer.required)
                return this.$t('errors.trailerRequired')
            return ''
        },
        weightError() {
            if (!this.$v.trip.weight.$dirty) return ''
            if (!this.$v.trip.weight.required)
                return this.$t('errors.weightRequired')
            return ''
        },
        trailerTypeError() {
            if (!this.$v.trip.trailerType.$dirty) return ''
            if (!this.$v.trip.trailerType.required)
                return this.$t('errors.trailerTypeRequired')
            return ''
        },
        messageError() {
            if (!this.$v.trip.message.$dirty) return ''
            if (!this.$v.trip.message.required)
                return this.$t('errors.messageRequired')
            return ''
        },
    },
    validations: {
        trip: {
            time: {
                required,
                isEndTimeAfterStartTime(value) {
                    return value[1] > value[0]
                },
                isTimeNotOverlapping(value) {
                    return !this.allCorrections.some(trip => {
                        const tripStart = moment(
                            trip.correction_begin.split('+')[0]
                        )
                        const tripEnd = moment(
                            trip.correction_end.split('+')[0]
                        )

                        const valueStart = moment(
                            `${this.selectedDay.date}T${value[0]}`
                        )
                        const valueEnd = moment(
                            `${this.selectedDay.date}T${value[1]}`
                        )

                        return (
                            valueStart.isBetween(
                                tripStart,
                                tripEnd,
                                'second',
                                '[]'
                            ) ||
                            valueEnd.isBetween(
                                tripStart,
                                tripEnd,
                                'second',
                                '[]'
                            )
                        )
                    })
                },
            },
            distance: {
                required,
                minValue: minValue(0),
            },
            trailer: {
                required,
            },
            weight: {
                required: requiredIf(vm => vm.trailer?.id === 'OTHER_TRAILER'),
            },
            trailerType: {
                required: requiredIf(vm => vm.trailer?.id === 'OTHER_TRAILER'),
            },
            message: {
                required,
            },
        },
    },
    watch: {
        'trip.trailer'(trailer) {
            if (!trailer) {
                return
            }

            if (trailer.id === 'NO_TRAILER' || trailer.id === 'OTHER_TRAILER') {
                this.trip.trailerType = null
                this.trip.weight = ''
                return
            }

            this.trip.weight = (trailer?.weight / 1000).toFixed(2)
            this.trip.trailerType = trailer.type
        },
    },
    methods: {
        ...mapActions('lsva', ['createTrack']),
        cleanForm() {
            this.trip = { ...DEFAULT_TRIP }
        },
        async create() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                return
            }

            const notification = {
                title: this.$t('shared.success'),
                text: this.$t('tripCreated'),
            }

            try {
                await this.createTrack(this.trip)

                this.$notify(notification)
            } catch (error) {
                this.$notify({
                    title: this.$t('shared.error'),
                    text: httpHelper.parseError(error),
                })
            }

            this.cleanForm()
            this.$emit('close')
        },
        cancel() {
            this.cleanForm()
            this.$emit('close')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "createTrip": "Create trip",
        "time": "Time from - to",
        "distance": "Distance driven in KM",
        "trailer": "Trailer",
        "trailerType": "Trailer type",
        "weight": "Total weight of the trailer",
        "message": "Reason for manual entry",
        "noTrailer": "No trailer",
        "otherTrailer": "Other trailer",
        "create": "Save",
        "cancel": "Cancel",
        "tripCreated": "Trip created",
        "reasons" : {
            "SERVICE_REGISTRATION": "Service registration",
            "SERVICE_ERROR": "Service error",
            "JOURNEY_WITHOUT_DEVICE": "Journey without device"
        },
        "errors": {
            "timeRequired": "Time is required",
            "endTimeAfterStartTime": "End time must be after start time",
            "isTimeNotOverlapping": "Time is overlapping with another correction",
            "distanceRequired": "Distance is required",
            "distanceMinValue": "Distance must be greater than 0",
            "trailerRequired": "Trailer is required",
            "weightRequired": "Weight is required",
            "trailerTypeRequired": "Trailer type is required",
            "messageRequired": "Message is required"
        }
    },
    "de": {
        "createTrip": "Fahrt erstellen",
        "time": "Zeit von - bis",
        "distance": "Gefahrene Distanz in KM",
        "trailer": "Anhänger",
        "trailerType": "Anhängertyp",
        "weight": "Gesamtgewicht des Anhängers",
        "message": "Grund für manuelle Erfassung",
        "noTrailer": "Kein Anhänger",
        "otherTrailer": "Anderer Anhänger",
        "create": "Speichern",
        "cancel": "Abbrechen",
        "tripCreated": "Fahrt erstellt",
        "reasons" : {
            "SERVICE_REGISTRATION": "Service-Registrierung",
            "SERVICE_ERROR": "Service-Fehler",
            "JOURNEY_WITHOUT_DEVICE": "Fahrt ohne Gerät"
        },
        "errors": {
            "timeRequired": "Zeit ist erforderlich",
            "endTimeAfterStartTime": "Endzeit muss nach Startzeit liegen",
            "isTimeNotOverlapping": "Zeit übersch neidet sich mit einer anderen Korrektur",
            "distanceRequired": "Distanz ist erforderlich",
            "distanceMinValue": "Distanz muss größer als 0 sein",
            "trailerRequired": "Anhänger ist erforderlich",
            "weightRequired": "Gewicht ist erforderlich",
            "trailerTypeRequired": "Anhängertyp ist erforderlich",
            "messageRequired": "Nachricht ist erforderlich"
        }
      },
    "fr": {
        "createTrip": "Créer un voyage",
        "time": "Temps de - à",
        "distance": "Distance parcourue en KM",
        "trailer": "Remorque",
        "trailerType": "Type de remorque",
        "weight": "Poids total de la remorque",
        "message": "Raison de l'entrée manuelle",
        "noTrailer": "Pas de remorque",
        "otherTrailer": "Autre remorque",
        "create": "Sauvegarder",
        "cancel": "Annuler",
        "tripCreated": "Trajet créé",
        "reasons" : {
            "SERVICE_REGISTRATION": "Enregistrement de service",
            "SERVICE_ERROR": "Erreur de service",
            "JOURNEY_WITHOUT_DEVICE": "Voyage sans appareil"
        },
        "errors": {
            "timeRequired": "Le temps est requis",
            "endTimeAfterStartTime": "L'heure de fin doit être après l'heure de début",
            "isTimeNotOverlapping": "Le temps se chevauche avec une autre correction",
            "distanceRequired": "La distance est requise",
            "distanceMinValue": "La distance doit être supérieure à 0",
            "trailerRequired": "La remorque est requise",
            "weightRequired": "Le poids est requis",
            "trailerTypeRequired": "Le type de remorque est requis",
            "messageRequired": "Le message est requis"
        }
    },
    "it":
    {
        "createTrip": "Crea viaggio",
        "time": "Tempo da - a",
        "distance": "Distanza percorsa in KM",
        "trailer": "Rimorchio",
        "trailerType": "Tipo di rimorchio",
        "weight": "Peso totale del rimorchio",
        "message": "Motivo dell'inserimento manuale",
        "noTrailer": "Nessun rimorchio",
        "otherTrailer": "Altro rimorchio",
        "create": "Salva",
        "cancel": "Annulla",
        "tripCreated": "Viaggio creato",
        "reasons" : {
            "SERVICE_REGISTRATION": "Registrazione del servizio",
            "SERVICE_ERROR": "Errore del servizio",
            "JOURNEY_WITHOUT_DEVICE": "Viaggio senza dispositivo"
        },
        "errors": {
            "timeRequired": "Il tempo è richiesto",
            "endTimeAfterStartTime": "L'ora di fine deve essere successiva all'ora di inizio",
            "isTimeNotOverlapping": "Il tempo si sovrappone a un'altra correzione",
            "distanceRequired": "La distanza è richiesta",
            "distanceMinValue": "La distanza deve essere maggiore di 0",
            "trailerRequired": "Il rimorchio è richiesto",
            "weightRequired": "Il peso è richiesto",
            "trailerTypeRequired": "Il tipo di rimorchio è richiesto",
            "messageRequired": "Il messaggio è richiesto"
        }
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-trip-create {
    padding: 24px;
    border-radius: 12px;
    background: $color-gray-light-2;

    &__header {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 700;
    }

    &__form {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
    }

    &__footer {
        margin-top: 24px;
        display: flex;
        gap: 8px;
    }
}
</style>
