<template>
    <Pill :variant="statusVariant">
        {{ statusText }}
    </Pill>
</template>

<script>
import Pill from '@/components/redesigned/Pill.vue'

export default {
    name: 'LsvaDeclarationStatus',
    components: {
        Pill,
    },
    props: {
        declaration: {
            type: Object,
            required: true,
        },
    },
    computed: {
        statusText() {
            const {
                has_manual_corrections,
                needs_manual_correction,
                needs_manual_correction_cause,
            } = this.declaration

            if (!needs_manual_correction && !has_manual_corrections) {
                return this.$t('recorded')
            }

            if (!needs_manual_correction && has_manual_corrections) {
                return this.$t('corrected')
            }

            if (needs_manual_correction) {
                if (
                    needs_manual_correction_cause === 'CONNECTION_LOST' ||
                    needs_manual_correction_cause ===
                        'CONNECTION_LOST_ON_NEXT_DAY'
                ) {
                    return this.$t('noConnection')
                }
                if (needs_manual_correction_cause === 'NOT_INSTALLED') {
                    return this.$t('notInstalled')
                }
                if (needs_manual_correction_cause === 'INSTALLATION_DATE') {
                    return this.$t('installationDate')
                }
            }

            return this.$t('unknown')
        },
        statusVariant() {
            const {
                has_manual_corrections,
                needs_manual_correction,
                needs_manual_correction_cause,
            } = this.declaration

            if (!needs_manual_correction && !has_manual_corrections) {
                return 'disabled'
            }
            if (!needs_manual_correction && has_manual_corrections) {
                return 'primary'
            }
            if (needs_manual_correction) {
                if (
                    [
                        'CONNECTION_LOST',
                        'CONNECTION_LOST_ON_NEXT_DAY',
                        'NOT_INSTALLED',
                        'INSTALLATION_DATE',
                    ].includes(needs_manual_correction_cause)
                ) {
                    return 'danger'
                }
            }

            return 'default'
        },
    },
}
</script>

<i18n>
{
    "en": {
        "corrected": "Corrected",
        "recorded": "Recorded",
        "noConnection": "No connection",
        "notInstalled": "Not installed",
        "installationDate": "Installation date",
        "unknown": "Unknown"
    },
    "de": {
        "corrected": "Korrigiert",
        "recorded": "Aufgezeichnet",
        "noConnection": "Keine Verbindung",
        "notInstalled": "Nicht eingebaut",
        "installationDate": "Installationsdatum",
        "unknown": "Unbekannt"
    },
    "fr": {
        "corrected": "Corrigé",
        "recorded": "Enregistré",
        "noConnection": "Pas de connexion",
        "notInstalled": "Non installé",
        "installationDate": "Date d'installation",
        "unknown": "Inconnu"
    },
    "it": {
        "corrected": "Corretto",
        "recorded": "Registrato",
        "noConnection": "Nessuna connessione",
        "notInstalled": "Non installato",
        "installationDate": "Data di installazione",
        "unknown": "Sconosciuto"
    }

}
</i18n>
