<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        :viewbox-width="9"
        :viewbox-height="17"
        @click="$emit('click')"
    >
        <g clip-path="url(#clip0_111_1832)">
            <path
                d="M1.20776 16.5C1.07443 16.5 0.94843 16.448 0.85443 16.3533C0.659764 16.1587 0.659764 15.8413 0.85443 15.646L8.0011 8.5L0.85443 1.35333C0.759764 1.25933 0.707764 1.13333 0.707764 1C0.707764 0.866667 0.759764 0.740667 0.85443 0.646667C0.94843 0.552 1.07443 0.5 1.20776 0.5C1.3411 0.5 1.4671 0.552 1.5611 0.646667L8.70776 7.79333C9.0971 8.18267 9.09776 8.81667 8.70843 9.206L1.5611 16.3533C1.4671 16.448 1.3411 16.5 1.20776 16.5Z"
                :fill="color"
            />
        </g>
        <defs>
            <clipPath id="clip0_111_1832">
                <rect
                    width="8.29233"
                    height="16"
                    fill="white"
                    transform="translate(0.707764 0.5)"
                />
            </clipPath>
        </defs>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ChevronLeftIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    fill-rule: evenodd;
}
</style>
