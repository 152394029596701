<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        :viewbox-width="9"
        :viewbox-height="17"
        @click="$emit('click')"
    >
        <g clip-path="url(#clip0_111_1789)">
            <path
                d="M7.79267 16.5C7.65933 16.5 7.53333 16.448 7.43933 16.3533L0.292667 9.20667C0.104 9.018 0 8.76733 0 8.50067C0 8.234 0.103333 7.98267 0.292 7.794L7.43933 0.646667C7.53333 0.552 7.65933 0.5 7.79267 0.5C7.926 0.5 8.052 0.552 8.146 0.646667C8.24067 0.740667 8.29267 0.866667 8.29267 1C8.29267 1.13333 8.24067 1.25933 8.146 1.35333L0.999333 8.5L8.146 15.6467C8.24067 15.7413 8.29267 15.8667 8.29267 16C8.29267 16.1333 8.24067 16.2593 8.146 16.3533C8.05133 16.4473 7.926 16.5 7.79267 16.5Z"
                :fill="color"
            />
        </g>
        <defs>
            <clipPath id="clip0_111_1789">
                <rect
                    width="8.29267"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ChevronLeftIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    fill-rule: evenodd;
}
</style>
