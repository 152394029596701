<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        color="transparent"
        :viewbox-width="22"
        :viewbox-height="16"
        :fill-color="color"
        @click="$emit('click')"
    >
        <g clip-path="url(#clip0_426_960)">
            <path
                d="M0.695646 0C1.07965 0 1.3913 0.311652 1.3913 0.695652L1.3913 9.04348C1.3913 9.42748 1.70295 9.73913 2.08695 9.73913L19.8854 9.73913L16.2031 6.05681C16.0714 5.9251 15.9991 5.75072 15.9991 5.56522C15.9991 5.37971 16.0714 5.20441 16.2031 5.07362C16.3348 4.94191 16.5101 4.86957 16.6956 4.86957C16.8812 4.86957 17.0565 4.94191 17.1872 5.07362L22.0568 9.94319C22.1217 10.0081 22.1727 10.0842 22.208 10.1704C22.2108 10.1779 22.2145 10.1862 22.2173 10.1946C22.246 10.2734 22.2609 10.3532 22.2609 10.4348C22.2609 10.5164 22.246 10.598 22.2163 10.6769C22.2145 10.6834 22.2117 10.6899 22.2098 10.6963C22.1727 10.7854 22.1217 10.8624 22.0559 10.9273L17.1872 15.7959C17.0565 15.9277 16.8812 16 16.6956 16C16.5101 16 16.3348 15.9277 16.2041 15.7959C16.0723 15.6652 16 15.4899 16 15.3043C16 15.1188 16.0723 14.9435 16.2041 14.8128L19.8864 11.1304L2.08695 11.1304C0.935879 11.1304 -5.72205e-06 10.1946 -5.72205e-06 9.04348L-5.72205e-06 0.695652C-5.72205e-06 0.311652 0.311646 0 0.695646 0Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_426_960">
                <rect width="22.2609" height="16" rx="8" fill="white" />
            </clipPath>
        </defs>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ChildIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: 'currentColor',
        },
    },
}
</script>
